#dump-modal {
  overflow:hidden;
  position:fixed;
  left: 0;
  bottom: 2rem;
  display: block;

  @include media-breakpoint-down(md) {
    display: none !important;
  }
}