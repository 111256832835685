$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
    (
      0: 0,
      1: ($spacer * .25), // 4px
      2: ($spacer * .5), // 8px
      3: $spacer, // 16px
      4: ($spacer * 1.5), // 24px
      5: ($spacer * 2), // 32px
      6: ($spacer * 2.5), // 40px
      7: ($spacer * 3), // 48px
      8: ($spacer * 3.5), // 56px
      9: ($spacer * 4), // 64px
      10: ($spacer * 5) // 80px
    ),
    $spacers
);
