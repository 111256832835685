a.ems-external-link:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  margin: 0 .35em;
  font-size: .8em;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f35d";
}