@import "bootstrap-variables";
@import "utilities/spacers";

@import "~bootstrap";
@import "~@fontsource/mulish/latin-300.css";
@import "~@fontsource/mulish/latin-400.css";
@import "~@fontsource/mulish/latin-700.css";
$fa-font-path: "../../node_modules/@fortawesome/fontawesome-free/webfonts" !default;
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";
$admin-menu-icon-fill: 'white';
$admin-menu-icon-size: 16;
$admin-menu-font-color: #FFFFFF;
@import "~@elasticms/admin-menu/css/style";

@import "functions";

@import "mixins/faw";

@import "utilities/external-link";
@import "utilities/icon-links";

@import "components/aria";
@import "components/back2top";
@import "components/jumbotron";
@import "components/layout";
@import "components/list";
@import "components/navbar";
@import "components/multilevel_navbar";
@import "components/releases";
@import "components/table";
@import "components/text";
@import "components/cookiesBanner";
@import "components/languages-page";

@import "pages/with_banner";
@import "pages/error";
@import "pages/blocks";

@import "custom";
@import "debug";
